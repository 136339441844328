<template>
  <div class="d-flex mt-2 pb-1 justify-content-between">
    <div>
      <b-button
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
        @click="$router.back()"
      >
        <feather-icon
          icon="LCancelIcon"
          size="16"
        />
        {{ $t('Cancel') }}
      </b-button>
    </div>
    <div class="d-flex">
      <b-overlay
        v-for="(item) in buttonsList"
        :key="item.title"
        :show="item.isLoading"
        rounded
        opacity="0.6"
        spinner-small
        :spinner-variant="item.color ? item.color : 'secondary'"
        class="d-inline-block ml-1"
      >
        <b-button
          :variant="item.color ? item.color : 'secondary'"
          class="font-medium-1 font-weight-bold px-3"
          :disabled="isLoading || isCreateForm && item.actionKey !== draftBtn.actionKey"
          @click="handleSubmit(item.actionKey)"
        >
          <feather-icon
            v-show="Object.hasOwn(item, 'icon') && item.icon"
            icon="LPrintIcon"
            size="16"
          />
          {{ item.title }}
        </b-button>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { BButton, BOverlay } from 'bootstrap-vue'
import jsonToFormData from '@/libs/jsonToFormData'
import { mapGetters } from 'vuex'
import { printContent } from '@/views/main/orders/components/helpers'
import { scrollToError } from '@core/utils/utils'
import config from './libs'
import mainConfig from '../../config'

export default {
  name: 'FormBottom',
  components: {
    BOverlay,
    BButton,
  },
  props: {
    formRef: {
      type: Object,
      required: true,
    },
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
    }
  },
  computed: {
    ...mapGetters('sub-rent', ['getState']),
    buttonsList() {
      return [
        this.draftBtn,
        this.voidBtn,
        this.printBtn,
        this.saveEmailBtn,
        this.receivedBtn,
      ]
    },
    isVoidOrder() {
      return this.$store.state[this.MODULE_NAME].order?.status === 5
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    handleSubmit(actionKey) {
      if (actionKey === 'print') {
        return this.printContent()
      }

      this.formRef.subRentQuoteForm.validate().then(success => {
        if (success) {
          if (actionKey === this.saveEmailBtn.actionKey || actionKey === this.receivedBtn.actionKey) {
            this.submitLogic('draft_ready', actionKey)
          } else {
            this.submitLogic(actionKey)
          }
        } else {
          scrollToError(this, this.formRef.subRentQuoteForm)
        }
      })
    },
    submitLogic(actionKey, callBackActionKey) {
      this.isLoading = true

      const body = jsonToFormData({
        ...this.getState,
        action: actionKey,
        id: this.id,
        _method: this.isCreateForm || actionKey === this.voidBtn.actionKey ? undefined : 'PUT',
      })

      // eslint-disable-next-line no-nested-ternary
      return this.sendNotification(this, body, `${this.MODULE_NAME}/${actionKey === this.voidBtn.actionKey ? 'voidSubRental' : this.isCreateForm ? 'create' : 'updateSubRental'}`)
        .then(response => {
          const { data } = response.data
          if (actionKey === 'draft_ready') {
            return this.submitLogic(callBackActionKey)
          }
          if (actionKey !== this.saveEmailBtn.actionKey || actionKey !== this.receivedBtn.actionKey) {
            this.redirect(actionKey, data.id)
          }
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, {})
        })
        .catch(err => {
          this.errorNotification(this, err)
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
          const element = document.getElementById('form-header')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
            element.focus()
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    redirect(actionKey, id) {
      if (this.isCreateForm) {
        this.$router.push({ name: 'sub-rent-request-for-quote', params: { id } })
      } else {
        this.getSubRentItem(this)
      }
    },
  },
  setup() {
    const {
      MODULE_NAME, model, emptyCallback, getSubRentItem,
    } = mainConfig()
    const {
      draftBtn, voidBtn, printBtn, saveEmailBtn, receivedBtn,
    } = config()

    return {
      MODULE_NAME,
      model,
      emptyCallback,
      printContent,
      getSubRentItem,
      draftBtn,
      voidBtn,
      printBtn,
      saveEmailBtn,
      receivedBtn,
    }
  },
}
</script>
